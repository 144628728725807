import React from "react";
import "./Programs.css";
import {programsData} from "../../data/programsData"
import RightArrow from "../../assets/rightArrow.png"
const Programs = () => {
  return (
    <div className="Programs" id="programs">
      <div className="program-header">
        <span className="stroke-text">Explore our</span>
        <span>Programs</span>
        <span className="stroke-text">to shape your</span>
      </div>
      <div className="programs-categories">
         {programsData.map((p)=>(
           <div className="categories">
                {p.image}
                <span>{p.heading}</span>
                <span>{p.details}</span>
                <div className="join-now">
                    <span>Join Now</span>
                    <img src={RightArrow} alt=""></img>
                </div>
           </div> 
         ))}   
      </div>
    </div>
  );
};

export default Programs;
